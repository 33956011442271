/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FormIssue } from '@se/data/forms/types.ts';
import { CommonStyles } from '@seeeverything/ui.primitives/src/common/commonStyles.ts';
import { RadioGroup } from '@seeeverything/ui.primitives/src/components/CheckboxRadioGroup/RadioGroup.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import {
  IListItemLabel,
  ISelectionListItem,
} from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { Switch } from '@seeeverything/ui.primitives/src/components/Switch/Switch.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { TextButton } from '@seeeverything/ui.primitives/src/components/TextButton/TextButton.tsx';
import { TextFieldDropdown } from '@seeeverything/ui.primitives/src/components/TextFieldDropdown/TextFieldDropdown.tsx';
import { Transition } from '@seeeverything/ui.primitives/src/components/Transition/index.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { FontWeight } from '@seeeverything/ui.util/src/types.ts';
import { useCallback, useMemo } from 'react';
import { issueSlice } from '../../../redux/issue/index.ts';
import { useFormsDispatch, useFormsSelector } from '../../../redux/store.ts';
import { Action } from '../../FormActionPlan/components/Action.tsx';
import { AddAction } from '../../FormActionPlan/components/AddAction.tsx';
import { TextAnswer } from '../../TextAnswer/TextAnswer.tsx';

export type IssueCoachingProps = {
  instanceId: string;
  issue: FormIssue;
  lineId: string;
  requirement: 'MANDATORY' | 'RECOMMENDED';
};

export const IssueCoaching: React.FC<IssueCoachingProps> = ({
  instanceId,
  issue,
  lineId,
  requirement,
}) => {
  const dispatch = useFormsDispatch();

  const actionPlan = useFormsSelector(
    (state) => state.formActionPlan.actionPlan[lineId],
  );

  const issueActions = useMemo(() => {
    if (!actionPlan?.plan) return [];
    if (actionPlan.isLoading) return [];
    if (actionPlan.loadError) return [];
    if (actionPlan.plan.hasSections === true) return [];
    return actionPlan.plan.actions.filter((a) => a.issueId === issue.id);
  }, [actionPlan.isLoading, actionPlan.loadError, actionPlan.plan, issue]);

  const instanceCanEdit = useFormsSelector((state) =>
    Boolean(state.formInstance.instances?.[instanceId]?.permissions.edit),
  );

  const specifyCauseAndCoachingSelected =
    issue.coaching.specifyCauseAndCoaching.isSelected;

  const canChangeSpecifyCauseAndCoaching =
    issue.coaching.specifyCauseAndCoaching.canChange && instanceCanEdit;

  const primaryCauseId = issue.issueCauses.causes.find(
    (cause) => cause.isPrimary,
  )?.issueCauseId;

  const primaryCauseNotes = issue.issueCauses.causes.find(
    (cause) => cause.isPrimary,
  )?.notes;

  const issueActionLabel = useFormsSelector(
    (state) => state.tenantState.tenant?.locale.label.issueAction,
  );

  const additionalActionsGuidanceText = useFormsSelector(
    (state) =>
      state.tenantState.tenant.locale.forms.closeTheLoop
        .issueCoachingPlanAdditionalActionsGuidanceText,
  );

  const tenantCauses = useFormsSelector((state) => state.formIssue.issueCauses);

  const primaryCause = useMemo(
    () => tenantCauses?.find((cause) => cause.id === primaryCauseId),
    [primaryCauseId, tenantCauses],
  );

  const causeSelections = useMemo(
    () =>
      tenantCauses
        ?.filter((cause) => cause.isActive || cause.id === primaryCauseId)
        .map((cause) => ({
          id: cause.id,
          value: cause.label,
          content: { text: cause.label },
        })),
    [primaryCauseId, tenantCauses],
  );

  const hasActions = issueActions.length > 0;
  const hasOpenActions =
    hasActions &&
    issueActions.some((action) => ['Open', 'Overdue'].includes(action.status));

  const handleSelectAnswerChange = useCallback(
    (to: ISelectionListItem<IListItemLabel>) => {
      const selectedCause = causeSelections.find((cause) => cause.id === to.id);
      if (!selectedCause) return;

      dispatch(
        issueSlice.setPrimaryCause({
          index: tenantCauses.findIndex(
            (tenantCause) => tenantCause.id === to.id,
          ),
          issueId: issue.id,
          issueCauseId: selectedCause.id,
          label: selectedCause.value,
          notes: primaryCauseNotes,
          instanceId,
        }),
      );
    },
    [
      causeSelections,
      dispatch,
      tenantCauses,
      issue.id,
      primaryCauseNotes,
      instanceId,
    ],
  );

  const handlePrimaryCauseNotesChange = useCallback(
    (to: string) => {
      dispatch(
        issueSlice.setPrimaryCauseNotes({
          issueId: issue.id,
          notes: to,
          instanceId,
        }),
      );
    },
    [dispatch, instanceId, issue.id],
  );

  const handleCoachingConversationChange = useCallback(
    (to: string) => {
      dispatch(
        issueSlice.setCoachingConversation({
          issueId: issue.id,
          coachingConversation: to,
          instanceId,
        }),
      );
    },
    [dispatch, instanceId, issue.id],
  );

  const handleNoAdditionalActionReasonChange = useCallback(
    (to: string) => {
      dispatch(
        issueSlice.setPrimaryCauseNoActionsReason({
          issueId: issue.id,
          noActionsReason: to,
          instanceId,
        }),
      );
    },
    [dispatch, instanceId, issue.id],
  );

  const toggleActionsRequired = useCallback(
    (to: string) => {
      dispatch(
        issueSlice.setPrimaryCauseAdditionalAction({
          issueId: issue.id,
          actionsRequired: to === 'Yes',
          instanceId,
        }),
      );
    },
    [dispatch, instanceId, issue.id],
  );

  const handleSecondaryCausesClick = useCallback(() => {
    dispatch(
      issueSlice.displaySecondaryCausesDialog({
        issueId: issue.id,
        instanceId,
      }),
    );
  }, [dispatch, instanceId, issue.id]);

  const selectedSecondaryCausesLabels = issue.issueCauses.causes
    .filter((cause) => !cause.isPrimary && cause.isSelected)
    .map((cause) => cause.label);

  const toggleCauseDefined = useCallback(() => {
    dispatch(
      issueSlice.toggleDefineCauseAndCoachingSelected({
        instanceId,
        issueId: issue.id,
      }),
    );
  }, [dispatch, instanceId, issue.id]);

  const computedStyles = {
    switchDefineCause: css({
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      cursor: canChangeSpecifyCauseAndCoaching ? 'pointer' : undefined,
      ':hover': {
        borderRadius: 4,
        padding: '4px 12px',
        margin: '-4px -12px',
        backgroundColor: color.format(-0.06),
      },
    }),
  };

  const selectedValue = useMemo(() => {
    if (!primaryCauseId) return;

    const causeSelection = causeSelections.find(
      (cause) => cause.id === primaryCauseId,
    );
    if (causeSelection) return causeSelection;
    if (!primaryCause) return;

    return {
      id: primaryCauseId,
      value: primaryCause,
      content: { text: primaryCause.label },
    };
  }, [causeSelections, primaryCause, primaryCauseId]);

  const elActions = hasActions && (
    <div css={styles.actions}>
      {issueActions.map((action) => (
        <Action
          key={`CoachingIssueAction-${action.id}`}
          action={action}
          source={{ type: 'FORM', instanceId }}
        />
      ))}
    </div>
  );

  const elAdditionalActions = (
    <Transition.Collapse
      in={issue.actionsRequired === true}
      mountOnEnter={true}
      unmountOnExit={true}
      timeout={100}
    >
      <div css={styles.primaryCausesBase}>
        <Text>{additionalActionsGuidanceText}</Text>
        {elActions}
        {instanceCanEdit && (
          <AddAction
            message={
              hasActions
                ? `Add another ${issueActionLabel.toLowerCase()}.`
                : `Add a ${issueActionLabel.toLowerCase()}.`
            }
            error={Boolean(issue.errors.coaching.coachingIssueAction)}
            style={styles.addAction}
            instanceId={instanceId}
            issueId={issue.id}
            existingActions={issueActions}
          />
        )}
      </div>
    </Transition.Collapse>
  );

  const elNoActionsReason = (
    <Transition.Collapse
      in={issue.actionsRequired === false}
      mountOnEnter={true}
      unmountOnExit={true}
      timeout={100}
    >
      <div css={styles.additionalReason}>
        <div>
          <TextAnswer
            id={`no-additional-action-reason-${issue.id}`}
            floatingText={'Why not?'}
            isEnabled={instanceCanEdit}
            multiline={true}
            onChange={handleNoAdditionalActionReasonChange}
            value={issue.noActionsReason ?? ''}
            error={issue.errors.coaching.noActionsReason}
            style={styles.darkenTextOnMouseover}
          />
        </div>
        {hasActions && (
          <Text
            italic={true}
            style={
              issue.errors.coaching.noOpenActionsWithNoActionSelected
                ? styles.textError
                : undefined
            }
          >
            {hasOpenActions
              ? `These actions were previously created. Cancel them if appropriate, otherwise if they're valid select Yes above.`
              : 'Previously created actions:'}
          </Text>
        )}
        {elActions}
      </div>
    </Transition.Collapse>
  );

  const elPrimaryCauseDetails = (
    <Transition.Fade
      in={Boolean(primaryCauseId)}
      mountOnEnter={true}
      unmountOnExit={true}
      timeout={100}
    >
      <TextAnswer
        id={`details-${issue.id}`}
        floatingText={
          primaryCause?.reasonLabel ??
          'Why do you think this is the primary cause? (Required)'
        }
        isEnabled={instanceCanEdit}
        multiline={true}
        onChange={handlePrimaryCauseNotesChange}
        value={primaryCauseNotes}
        error={issue.errors.coaching.primaryCauseNotes}
        style={styles.causeText}
      />
    </Transition.Fade>
  );

  const elSecondaryCauses = (
    <>
      <Text weight={FontWeight.bold}>{'Secondary Cause (Optional)'}</Text>
      <TextButton
        onClick={handleSecondaryCausesClick}
        textButtonStyle={styles.selectSecondaryCausesButton}
        textStyle={styles.selectSecondaryCausesButtonText}
        highlightOnHover={true}
        icon={Icons.animation}
      >
        {instanceCanEdit
          ? secondaryCausesTextEditable(selectedSecondaryCausesLabels)
          : secondaryCausesTextNotEditable(selectedSecondaryCausesLabels)}
      </TextButton>
    </>
  );

  return (
    <div css={styles.base}>
      {requirement === 'RECOMMENDED' && (
        <div
          css={computedStyles.switchDefineCause}
          onClick={
            canChangeSpecifyCauseAndCoaching ? toggleCauseDefined : undefined
          }
        >
          <Text cursor={'inherit'}>
            {`Do you want to define a cause for this issue and outline coaching activity?`}
          </Text>
          <Switch
            isChecked={specifyCauseAndCoachingSelected}
            isEnabled={instanceCanEdit && canChangeSpecifyCauseAndCoaching}
            tooltip={
              !canChangeSpecifyCauseAndCoaching && instanceCanEdit
                ? 'This cannot be unchecked as there are cause and/or coaching activities defined.'
                : undefined
            }
          />
        </div>
      )}
      <Transition.Collapse
        in={specifyCauseAndCoachingSelected}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <div css={styles.primaryCausesBase}>
          <Text weight={FontWeight.bold}>{'Primary Cause'}</Text>
          <div css={styles.primaryCausesOuter}>
            <div css={styles.dropdown}>
              <TextFieldDropdown
                id={`primary-cause-${issue.id}`}
                InputProps={{ type: 'text', autoComplete: 'off' }}
                isEnabled={instanceCanEdit}
                label={'Select the primary cause of this issue'}
                onChange={handleSelectAnswerChange}
                selections={causeSelections}
                value={selectedValue}
                error={issue.errors.coaching.primaryCause}
              />
            </div>
            {elPrimaryCauseDetails}
          </div>
          <Text weight={FontWeight.bold}>{'Coaching Conversation'}</Text>
          <div css={styles.primaryCausesOuter}>
            <TextAnswer
              id={`coaching-conversation-summary-${issue.id}`}
              floatingText={'Coaching conversation summary (Required)'}
              isEnabled={instanceCanEdit}
              multiline={true}
              onChange={handleCoachingConversationChange}
              value={issue.coachingConversation ?? ''}
              error={issue.errors.coaching.coachingConversation}
            />
          </div>
          <div css={styles.additionalActionQuestion}>
            <Text>{`Are additional ${str.plural(
              issueActionLabel.toLowerCase(),
            )} required to prevent this from occurring again?`}</Text>
            <RadioGroup
              direction={'horizontal'}
              value={
                issue.actionsRequired === true
                  ? 'Yes'
                  : issue.actionsRequired === false
                    ? 'No'
                    : undefined
              }
              options={[
                { id: 'Yes', label: 'Yes' },
                { id: 'No', label: 'No' },
              ]}
              onChange={toggleActionsRequired}
              isEnabled={instanceCanEdit}
              error={issue.errors.coaching.actionsRequiredSelection}
            />
          </div>
          {elAdditionalActions}
          {elNoActionsReason}
          {elSecondaryCauses}
        </div>
      </Transition.Collapse>
    </div>
  );
};

const secondaryCausesTextEditable = (selectedCauses: string[] = []) => {
  const [first, second] = selectedCauses;
  const numCauses = selectedCauses.length ?? 0;
  switch (numCauses) {
    case 0:
      return `Add secondary causes to this issue (Nothing selected).`;
    case 1:
      return `Add or change secondary causes on this issue. ${first} is selected as a cause.`;
    case 2:
      return `Add or change secondary causes on this issue. ${first} and ${second} are selected as causes.`;
    default: {
      const remaining = numCauses - 2;
      return `Add or change secondary causes on this issue. ${first}, ${second}, and ${remaining} ${str.plural(
        'other',
        remaining,
      )} are selected as causes.`;
    }
  }
};

const secondaryCausesTextNotEditable = (selectedCauses: string[] = []) => {
  const [first, second] = selectedCauses;
  const numCauses = selectedCauses.length ?? 0;
  switch (numCauses) {
    case 0:
      return `No secondary causes were selected.`;
    case 1:
      return `${first} selected as a cause.`;
    case 2:
      return `${first} and ${second} were selected as causes.`;
    default: {
      const remaining = numCauses - 2;

      return `${first}, ${second}, and ${remaining} ${str.plural(
        'other',
        remaining,
      )} were selected as causes.`;
    }
  }
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
    padding: '0 12px',
  }),
  primaryCausesOuter: css({
    display: 'flex',
    flexWrap: 'wrap',
    padding: '3px 0px',
    ':hover': {
      padding: '7px 12px',
      margin: '-4px -12px',
      backgroundColor: color.format(-0.06),
    },
  }),
  additionalActionQuestion: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    ':hover': {
      padding: '8px 12px',
      margin: '-8px -12px',
      backgroundColor: color.format(-0.06),
    },
  }),
  darkenTextOnMouseover: css({
    ':hover': {
      padding: '10px 27px 20px 12px',
      margin: '-10px -12px',
      backgroundColor: color.format(-0.06),
    },
  }),
  textError: css({
    color: COLORS.ERROR_RED,
  }),
  selectSecondaryCausesButton: {
    display: 'flex',
    flex: '1 1 auto',
    cursor: 'pointer',
    justifyContent: 'flex-start',
    padding: 10,
    margin: '-3px -10px',
  },
  selectSecondaryCausesButtonText: css({
    ...CommonStyles.MaterialCubicTransitions,
    marginLeft: 4,
  }),
  primaryCausesBase: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
  }),
  actions: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  additionalReason: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
  }),
  dropdown: css({
    flex: '1 1 225px',
    padding: '0 15px 10px 0',
  }),
  causeText: css({ flex: '4 1 375px' }),
  addAction: css({
    padding: '10px 13px',
    margin: '-10px -12px',
  }),
};
