/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import ReactPlayer from 'react-player';
import { ErrorRetry } from '../ErrorRetry/index.ts';
import { AspectRatioType } from './types.ts';

export type VideoPlayerProps = {
  url: string;
  error?: string;
  aspectRatio?: AspectRatioType;
  width?: number;
  onError?: (err: any) => void;
  onRefreshClick?: () => void;
};

/**
 * Component for streaming video in the client. Wrapper on `react-player`.
 *
 * @see https://www.npmjs.com/package/react-player
 */
export const VideoPlayer: React.FC<VideoPlayerProps> = ({
  aspectRatio = '16:9',
  url,
  error,
  onError,
  onRefreshClick,
}) => {
  const paddingTop = React.useMemo(
    () => paddingForAspect(aspectRatio),
    [aspectRatio],
  );

  const computedStyles = {
    base: css({
      position: 'relative',
      width: '100%',
      background: 'black',
      padding: `${paddingTop}% 1px 1px 0`, // Over scan the video slightly to ensure containers are filled.
    }),
    errorBase: css({
      position: 'relative',
      paddingTop: `${paddingTop}%`,
    }),
  };

  if (error) {
    return (
      <ErrorRetry
        style={computedStyles.errorBase}
        onClick={onRefreshClick}
        message={error}
      />
    );
  }

  return (
    <div css={computedStyles.base}>
      <ReactPlayer
        url={url}
        controls={true}
        onError={onError}
        width={'100%'}
        height={'100%'}
        stopOnUnmount={true}
        style={{ position: 'absolute', inset: 0 }}
        playing={true}
      />
    </div>
  );
};

// Creates a fixed aspect ratio, responsive size video player.
const paddingForAspect = (ratio: AspectRatioType) => {
  const aspectRatio = (widthAspect: number, heightAspect: number) =>
    100 / (widthAspect / heightAspect);

  switch (ratio) {
    case '16:9':
      return aspectRatio(16, 9);
    case '3:2':
      return aspectRatio(3, 2);
    case '4:3':
      return aspectRatio(4, 3);
    default:
      return aspectRatio(16, 9);
  }
};
