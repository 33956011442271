/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
// eslint-disable-next-line import/extensions
import Radio from '@mui/material/Radio';
// eslint-disable-next-line import/extensions
import MaterialRadioGroup from '@mui/material/RadioGroup';
// eslint-disable-next-line import/extensions
import FormLabel from '@mui/material/FormLabel';
// eslint-disable-next-line import/extensions
import FormControl from '@mui/material/FormControl';
// eslint-disable-next-line import/extensions
import FormControlLabel from '@mui/material/FormControlLabel';
// eslint-disable-next-line import/extensions
import FormHelperText from '@mui/material/FormHelperText';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { Tooltip } from '../Tooltip/Tooltip.tsx';
import { ItemLabel } from './components/ItemLabel.tsx';

export type RadioOption = {
  id: string;
  label: string;
};

export type RadioGroupProps = {
  value?: string;
  onChange?: (selectedId: string) => void;
  isEnabled?: boolean;
  options?: RadioOption[];
  title?: string;
  direction?: 'horizontal' | 'vertical';
  error?: string;
  helperText?: string;
  isFullWidth?: boolean;
  tooltip?: string;
};

export const RadioGroup: React.FC<RadioGroupProps> = ({
  value = null,
  isFullWidth = true,
  isEnabled = true,
  direction = 'vertical',
  options = [],
  onChange,
  title,
  error,
  helperText,
  tooltip,
}) => {
  // direction = 'horizontal';
  const isHorizontal = direction === 'horizontal';

  const elRadioGroup = (
    <FormControl
      error={Boolean(error)}
      fullWidth={isFullWidth}
      disabled={!isEnabled}
    >
      {title && <FormLabel>{title}</FormLabel>}
      <MaterialRadioGroup
        aria-label={title}
        name={title}
        value={value}
        onChange={(_, id) => onChange?.(id)}
        row={isHorizontal}
        sx={styles.radioGroup}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.id}
            value={option.id}
            control={
              <Radio size={'medium'} sx={styles.radio} disabled={!isEnabled} />
            }
            label={
              <ItemLabel
                ellipsis={true}
                value={option.label}
                textSize={16}
                center={isHorizontal}
                isEnabled={isEnabled}
              />
            }
            labelPlacement={isHorizontal ? 'top' : 'end'}
          />
        ))}
      </MaterialRadioGroup>
      {Boolean(error || helperText) && (
        <FormHelperText>{error || helperText}</FormHelperText>
      )}
    </FormControl>
  );

  return tooltip ? (
    <Tooltip title={tooltip}>{elRadioGroup}</Tooltip>
  ) : (
    elRadioGroup
  );
};

const styles = {
  radioGroup: css({
    '&.MuiRadioGroup-root': css({
      color: color.format(-0.6),
      display: 'flex',
      flexWrap: 'nowrap',
    }),
  }),
  radio: css({
    '&.MuiRadio-root': css({
      padding: 9,
    }),
  }),
};
