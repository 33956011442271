/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Paper } from '@seeeverything/ui.primitives/src/components/Paper/Paper.tsx';
import { Portal } from '@seeeverything/ui.primitives/src/components/Portal/Portal.tsx';
import { Transition } from '@seeeverything/ui.primitives/src/components/Transition/index.ts';
import { VideoPlayer } from '@seeeverything/ui.primitives/src/components/VideoPlayer/VideoPlayer.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';

export type VideoStreamerProps = {
  url: string;
  error?: string;
  header?: React.ReactNode;
  onRefreshClick?: () => void;
  onError?: () => void;
  onMaskClick?: () => void;
};

/**
 * Video Player for streaming digital content videos.
 */
export const VideoStreamer: React.FC<VideoStreamerProps> = ({
  url,
  header,
  onMaskClick,
  error,
  onRefreshClick,
  onError,
}) => {
  const computedStyles = {
    base: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      borderRadius: 3,
      flex: '1 1 auto',
      maxWidth: '80%',
      position: 'relative',
      overflow: 'hidden',
      border: error ? `1px solid ${color.format(-0.18)}` : undefined,
    },
  } as const;

  return (
    <Portal>
      <div css={styles.mask} onClick={onMaskClick}>
        <Transition.Zoom in={true}>
          <Paper
            elevation={5}
            style={computedStyles.base}
            onClick={(e) => e.stopPropagation()}
          >
            {header}
            <VideoPlayer
              url={url}
              error={error}
              onRefreshClick={onRefreshClick}
              onError={onError}
            />
          </Paper>
        </Transition.Zoom>
      </div>
    </Portal>
  );
};

const styles = {
  mask: css({
    position: 'absolute',
    width: '100%',
    height: '100%',
    inset: 0,
    background: color.format(-0.5),
    boxSizing: 'border-box',
    zIndex: 13,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '6%',
  }),
};
