/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
// eslint-disable-next-line import/extensions
import MaterialAccordionSummary from '@mui/material/AccordionSummary';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { CommonStyles } from '../../common/commonStyles.ts';
import { Icons } from '../Icon/Icons.tsx';

export type AccordionSummaryProps = {
  expandIcon?: React.ReactElement | null; // nulling the icon will make the accordion have no expand icon.
  disableRipple?: boolean;
  role?: React.AriaRole;
  rootStyle?: SerializedStyles;
  expandedStyle?: SerializedStyles;
  children?: React.ReactNode;
};

/**
 * Items rendered as the title in an `Accordion`, when expanded these will show any `AccordionDetails`.
 */
export const AccordionSummary: React.FC<AccordionSummaryProps> = ({
  expandIcon = <Icons.arrowDropDown />,
  disableRipple = false,
  role = 'button',
  children,
  rootStyle,
  expandedStyle,
}) => {
  const styles = {
    base: css({
      '&.MuiAccordionSummary-root': css(
        {
          ...CommonStyles.MaterialCubicTransitions,
          padding: '0 16px',
          border: `solid 1px ${color.format(-0.1)}`,
          borderRadius: 4,
          background: color.format(-0.05),
        },
        rootStyle,
      ),

      '&.Mui-expanded': expandedStyle,
    }),
  };

  return (
    <MaterialAccordionSummary
      sx={styles.base}
      expandIcon={expandIcon}
      disableRipple={disableRipple}
      role={role}
    >
      {children}
    </MaterialAccordionSummary>
  );
};
