/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { CoachingSummarySectionInsight } from '@se/data/forms/types.ts';
import { Accordion } from '@seeeverything/ui.primitives/src/components/Accordion/Accordion.tsx';
import { AccordionDetails } from '@seeeverything/ui.primitives/src/components/Accordion/AccordionDetails.tsx';
import { AccordionSummary } from '@seeeverything/ui.primitives/src/components/Accordion/AccordionSummary.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { useState } from 'react';
import { CoachingSummaryHighlight } from './CoachingSummaryHighlight.tsx';

export type CoachingSummaryHighlightGroupProps = {
  instanceId: string;
  label: string;
  highlights: CoachingSummarySectionInsight[];
  sectionExpanded: boolean;
};

export const CoachingSummaryHighlightGroup: React.FC<
  CoachingSummaryHighlightGroupProps
> = ({ instanceId, label, highlights, sectionExpanded }) => {
  const [isExpanded, setIsExpanded] = useState(sectionExpanded);

  const elSummaryContent = (
    <div css={styles.contentInner}>
      <Icons.emojiEvents
        fill={COLORS.GOLD}
        style={styles.iconPadding}
        tooltip={'Highlight'}
        cursor={'pointer'}
      />
      <div css={styles.summaryText}>
        <Text
          color={color.format(-0.8)}
          ellipsis={true}
          style={styles.highlightLabel}
          cursor={'pointer'}
          weight={500}
        >
          {label}
        </Text>
      </div>
    </div>
  );

  return (
    <Accordion
      id={`highlight-${label}`}
      elevation={0}
      isExpanded={isExpanded}
      onChange={(e) => setIsExpanded(e.isExpanded)}
      hideDividers={true}
      isDefaultExpanded={true}
    >
      <AccordionSummary
        rootStyle={styles.accordionSummary}
        expandedStyle={styles.accordionExpanded}
      >
        <div css={styles.summaryContent}>
          {elSummaryContent}
          <Text
            color={COLORS.BLUE}
            size={14}
            cursor={'pointer'}
            style={styles.summaryShowHideText}
          >
            {isExpanded ? 'Hide' : 'Show'}
          </Text>
        </div>
      </AccordionSummary>
      <AccordionDetails style={styles.detailsBase}>
        <div css={styles.highlights}>
          {highlights.map((highlight) => (
            <CoachingSummaryHighlight
              key={highlight.id}
              instanceId={instanceId}
              highlight={highlight}
            />
          ))}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const styles = {
  accordionSummary: css({
    backgroundColor: '#fafad2',
  }),
  accordionExpanded: css({
    backgroundColor: '#fafad2',
  }),
  summaryText: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    textAlign: 'start',
  }),
  contentInner: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
  }),
  detailsBase: css({
    background: '#fefefe',
    border: `1px solid #cecece`,
    borderTop: 'none',
    borderRadius: '0 0 4px 4px',
    padding: '12px 16px',
  }),
  iconPadding: {
    paddingRight: 10,
  },
  summaryContent: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  }),
  summaryShowHideText: css({
    alignSelf: 'center',
    padding: '0 5px 0 20px',
  }),
  highlightLabel: css({
    textWrap: 'wrap',
    overflowWrap: 'anywhere',
  }),
  highlights: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  }),
};
