/** @jsxImportSource @emotion/react */
import { SerializedStyles } from '@emotion/react';
// eslint-disable-next-line import/extensions
import MaterialAccordionDetails from '@mui/material/AccordionDetails';
import { ReactNode } from 'react';

export interface IAccordionDetailsProps {
  style?: SerializedStyles;
  children?: ReactNode;
}

/**
 * Items rendered under an `AccordionSummary` when an `Accordion` is expanded.
 */
export const AccordionDetails: React.FC<{
  style?: SerializedStyles;
  children?: ReactNode;
}> = ({ children, style }) => (
  <MaterialAccordionDetails sx={style}>{children}</MaterialAccordionDetails>
);
