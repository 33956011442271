/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
// eslint-disable-next-line import/extensions
import MaterialAccordion from '@mui/material/Accordion';
import { useCallback } from 'react';

export interface IAccordionChangeEvent {
  id?: string;
  index?: number;
  isExpanded: boolean;
}

export type AccordionProps = {
  children: React.ReactNode;
  disableGutter?: boolean;
  elevation?: number;
  hideDividers?: boolean;
  id: string;
  index?: number;
  isDefaultExpanded?: boolean;
  isDisabled?: boolean;
  isExpanded?: boolean;
  isSquare?: boolean;
  onChange?: (e: IAccordionChangeEvent) => void;
  onlyMountWhenVisible?: boolean;
  style?: SerializedStyles;
  variant?: 'outlined' | 'elevation';
};

/**
 * Displays `AccordionSummary` and `AccordionDetails` as a collapsible layout element.
 */
export const Accordion: React.FC<AccordionProps> = ({
  children,
  disableGutter = true,
  elevation = 0,
  hideDividers = true,
  id,
  index = 0,
  isDefaultExpanded,
  isDisabled,
  isExpanded,
  isSquare = false,
  onChange,
  onlyMountWhenVisible = true,
  style,
  variant = 'elevation',
}) => {
  const handleChange = useCallback(
    (_: any, toIsExpanded: boolean) => {
      onChange?.({ id, index, isExpanded: toIsExpanded });
    },
    [id, index, onChange],
  );

  const styles = {
    base: css({
      transition: 'all 0.3s',
      '&:hover': {
        boxShadow: `0 0 3px 0 #c0c0c0`,
      },
      '&:before': {
        height: hideDividers ? 0 : 1,
      },
    }),
  };

  return (
    <MaterialAccordion
      defaultExpanded={isDefaultExpanded}
      disabled={isDisabled}
      expanded={isExpanded}
      square={isSquare}
      onChange={handleChange}
      disableGutters={disableGutter}
      elevation={isDisabled ? 0 : elevation}
      sx={[styles.base, style]}
      slotProps={{
        transition: {
          unmountOnExit: onlyMountWhenVisible,
          mountOnEnter: onlyMountWhenVisible,
        },
      }}
      variant={variant}
    >
      {children}
    </MaterialAccordion>
  );
};
