/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import moment from 'moment';

export interface IStreamHeaderProps {
  title: string;
  uploadedDate?: Date;
  uploadedBy?: string;
  error?: string;
  onCloseClick: () => void;
}

/**
 * Header on stream player.
 */
export const StreamHeader: React.FC<IStreamHeaderProps> = ({
  title,
  uploadedBy,
  uploadedDate,
  error,
  onCloseClick,
}) => {
  const computedStyles = {
    base: css({
      border: !error ? `solid 1px ${color.format(-0.18)}` : undefined,
    }),
  };

  return (
    <div css={[styles.base, computedStyles.base]}>
      <div css={styles.titleContainer}>
        <Icons.movie fill={color.format(-0.8)} />
        <Text style={styles.titleText}>{title}</Text>
      </div>
      {(uploadedDate || uploadedBy) && (
        <div css={styles.uploadedMeta}>
          {uploadedDate && (
            <Text style={styles.uploadText}>
              {`Uploaded on ${moment(uploadedDate).format('DD MMMM, YYYY')}`}
            </Text>
          )}
          {uploadedBy && (
            <Text style={styles.uploadText}>{`Uploaded by ${uploadedBy}`}</Text>
          )}
        </div>
      )}
      <Icons.clear onClick={onCloseClick} />
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    padding: '6px 12px',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: 'none',
    borderRadius: '3px 3px 0 0',
  }),
  titleContainer: css({
    display: 'flex',
    alignItems: 'center',
  }),
  uploadedMeta: css({
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'right',
  }),
  uploadText: css({
    color: color.format(-0.5),
    fontSize: 12,
  }),
  titleText: css({
    color: color.format(-0.8),
    marginLeft: 6,
  }),
};
