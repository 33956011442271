import { ServerMembership, TeamMembership } from '../types.ts';

export const translateMemberships = (
  memberships: ServerMembership[],
): TeamMembership[] =>
  memberships
    .map(
      (membership): TeamMembership => ({
        id: membership.team.id,
        path: membership.team.path,
        teamType: membership.team.type,
        name: membership.team.name,
        sourceKey: membership.sourceKey,
        isPrimary: membership.isPrimary,
      }),
    )
    .filter(isLowestLevelTeam)
    .sort(pathDepthComparator);

const alphabeticalComparator = (
  first: TeamMembership,
  second: TeamMembership,
) => {
  if (first.name < second.name) return -1;
  if (first.name > second.name) return 1;
  return 0;
};

const pathDepthComparator = (first: TeamMembership, second: TeamMembership) => {
  const firstPathDepth = first.path.split('/').length;
  const secondPathDepth = second.path.split('/').length;

  return firstPathDepth === secondPathDepth
    ? alphabeticalComparator(first, second)
    : firstPathDepth - secondPathDepth;
};

const isLowestLevelTeam = (
  teamMembership: TeamMembership,
  _: number,
  allMemberships: TeamMembership[],
): boolean => {
  const hasLowerLevelTeamMembership = allMemberships.some((membership) => {
    if (!membership.path.startsWith(teamMembership.path)) return false;

    const teamMembershipDepth = teamMembership.path.split('/').length;
    const membershipDepth = membership.path.split('/').length;

    return teamMembershipDepth < membershipDepth;
  });

  return !hasLowerLevelTeamMembership;
};
