/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Button } from '@seeeverything/ui.primitives/src/components/Button/Button.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { MaterialList } from '@seeeverything/ui.primitives/src/components/MaterialList/index.ts';
import { MaterialListItemClickEvent } from '@seeeverything/ui.primitives/src/components/MaterialList/types.ts';
import { OutsideAlerter } from '@seeeverything/ui.primitives/src/components/OutsideAlerter/OutsideAlerter.tsx';
import { Paper } from '@seeeverything/ui.primitives/src/components/Paper/Paper.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { Transition } from '@seeeverything/ui.primitives/src/components/Transition/index.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { value } from '@seeeverything/ui.util/src/value/index.ts';
import { memo, useCallback, useState } from 'react';
import { FormResult } from '../../../redux/form-score/types.ts';

const THEMES = {
  [FormResult.Fail]: {
    background: 'rgba(255, 0, 0, 0.1)',
    hoverBackground: 'rgba(255, 0, 0, 0.3)',
    ripple: COLORS.ERROR_RED,
  },
  [FormResult.Pass]: {
    background: color.create(COLORS.GREEN).alpha(0.3).css(),
    hoverBackground: color.create(COLORS.GREEN).alpha(0.5).css(),
    ripple: COLORS.GREEN,
  },
  [FormResult.Provisional]: {
    background: 'rgba(249, 195, 102, 0.3)',
    hoverBackground: 'rgba(249, 195, 102, 0.5)',
    ripple: 'rgb(249, 195, 102)',
  },
  [FormResult.NotApplicable]: {
    background: color.format(-0.1),
    hoverBackground: color.format(-0.2),
    ripple: color.format(-0.1),
  },
};

const getDropdownListItem = (
  result: FormResult,
  label: string,
  isCalculated: boolean,
) => {
  if (result === FormResult.Pass)
    return {
      id: 'Pass',
      text: label,
      value: 'Pass',
      icon: Icons.verifiedUser,
      iconFill: COLORS.GREEN,
      description: isCalculated ? 'This is the calculated result.' : undefined,
    };

  if (result === FormResult.Fail)
    return {
      id: 'Fail',
      text: label,
      value: 'Fail',
      icon: Icons.cancel,
      iconFill: COLORS.ERROR_RED,
      description: isCalculated ? 'This is the calculated result.' : undefined,
    };

  if (result === FormResult.NotApplicable)
    return {
      id: 'N/A',
      text: 'N/A',
      value: 'N/A',
    };

  return {
    id: 'Provisional',
    text: 'Provisional',
    value: 'Provisional',
    icon: Icons.clipboardPerson,
    iconFill: 'rgb(249, 195, 102)',
  };
};

export interface IResultDropdownProps {
  calculatedFormResult?: FormResult;
  canMarkProvisional: boolean;
  canOverruleNotApplicable: boolean;
  canOverruleResult: boolean;
  error?: string;
  failLabel: string;
  isEnabled: boolean;
  onChange: (to: FormResult) => void;
  passLabel: string;
  percentageScore?: number;
  result: FormResult;
  showLabels?: boolean;
}

/**
 * Field for displaying score result with configurable dropdown functionality
 */
const View: React.FC<IResultDropdownProps> = ({
  calculatedFormResult = FormResult.NotApplicable,
  canMarkProvisional,
  canOverruleNotApplicable,
  canOverruleResult,
  error,
  failLabel,
  isEnabled = true,
  onChange,
  passLabel,
  percentageScore,
  result,
  showLabels,
}) => {
  const [isShowingDropdown, setIsShowingDropdown] = useState(false);
  const hideDropdown = useCallback(() => setIsShowingDropdown(false), []);
  const showDropdown = useCallback(() => setIsShowingDropdown(true), []);
  const selectDropdownItem = useCallback(
    (e: MaterialListItemClickEvent) => {
      onChange?.(e.value as FormResult);
      setIsShowingDropdown(false);
    },
    [onChange],
  );

  const theme = THEMES[result];

  const computedStyles = {
    button: css({
      padding: 12,
      width: '100%',
      display: 'flex',
      boxSizing: 'border-box',
      borderRadius: 4,
      position: 'relative',
      opacity: 1, // Don't fade if disabled.
      background: theme.background,
      color: theme.ripple,
      '&:hover': {
        background: theme.hoverBackground,
      },
    }),
  };

  const canChangeResult = getCanChangeResult(
    isEnabled,
    canMarkProvisional,
    canOverruleResult,
    canOverruleNotApplicable,
    calculatedFormResult,
  );

  const invertedResult = invertResult(calculatedFormResult);
  const invertedResultLabel = getLabel(invertedResult, passLabel, failLabel);

  const selectionNotApplicableToPass =
    canOverruleNotApplicable &&
    getDropdownListItem(FormResult.Pass, passLabel, false);

  const selectionNotApplicableToFail =
    canOverruleNotApplicable &&
    getDropdownListItem(FormResult.Fail, failLabel, false);

  const selectionCalculatedResult =
    calculatedFormResult !== FormResult.NotApplicable &&
    getDropdownListItem(
      calculatedFormResult,
      getLabel(calculatedFormResult, passLabel, failLabel),
      true,
    );

  const selectionCalculatedResultToInvertedResult =
    canOverruleResult &&
    invertedResult !== FormResult.NotApplicable &&
    getDropdownListItem(invertedResult, invertedResultLabel, false);

  const selectionCalculatedResultToProvisional =
    canMarkProvisional &&
    getDropdownListItem(
      FormResult.Provisional,
      getLabel(FormResult.Provisional, passLabel, failLabel),
      false,
    );

  const selections = [
    selectionNotApplicableToPass,
    selectionNotApplicableToFail,
    selectionCalculatedResult,
    selectionCalculatedResultToInvertedResult,
    selectionCalculatedResultToProvisional,
  ].filter(Boolean);

  const scoreLabel =
    showLabels || result === FormResult.NotApplicable
      ? getLabel(result, passLabel, failLabel)
      : '';

  const percentageLabel =
    percentageScore !== undefined && result !== FormResult.NotApplicable
      ? `${scoreLabel ? ' -- ' : ''}${value.toPercentageString(
          percentageScore,
          2,
        )}`
      : '';
  const resultLabel = `${scoreLabel}${percentageLabel}`;

  return (
    <OutsideAlerter onClickedOutside={hideDropdown} style={styles.base}>
      <>
        <Button
          isEnabled={canChangeResult}
          style={computedStyles.button}
          onClick={showDropdown}
        >
          <>
            <Text style={styles.resultLabel}>{resultLabel}</Text>
            {canChangeResult && (
              <Icons.arrowDropDown
                fill={color.format(-0.8)}
                style={styles.dropdownIcon}
              />
            )}
          </>
        </Button>
        {canChangeResult && (
          <Paper elevation={1} style={styles.dropdown}>
            <Transition.Collapse in={isShowingDropdown}>
              <MaterialList
                items={selections}
                selectedId={result}
                onListItemClick={selectDropdownItem}
                isSelectable={false}
              />
            </Transition.Collapse>
          </Paper>
        )}
        {error && (
          <Text color={COLORS.ERROR_RED} size={12} style={styles.error}>
            {error}
          </Text>
        )}
      </>
    </OutsideAlerter>
  );
};

const invertResult = (result: FormResult): FormResult => {
  if (result === FormResult.NotApplicable) return FormResult.NotApplicable;
  if (result === FormResult.Fail) return FormResult.Pass;
  return FormResult.Fail;
};

const getLabel = (result: FormResult, passLabel: string, failLabel: string) => {
  if (result === FormResult.NotApplicable) return 'To be determined';
  if (result === FormResult.Fail) return failLabel;
  if (result === FormResult.Pass) return passLabel;
  return 'Provisional';
};

const getCanChangeResult = (
  isEnabled: boolean,
  canMarkProvisional: boolean,
  canOverruleResult: boolean,
  canOverruleNotApplicable: boolean,
  calculatedFormResult: FormResult,
) => {
  if (!isEnabled) return false;

  if (!canMarkProvisional && !canOverruleResult && !canOverruleNotApplicable)
    return false;

  return calculatedFormResult === FormResult.NotApplicable
    ? canOverruleNotApplicable
    : true;
};

const styles = {
  base: css({
    position: 'relative',
    flex: '1 1 auto',
    minWidth: 200,
  }),
  resultLabel: css({
    flex: '1 1 auto',
    textAlign: 'center',
    cursor: 'pointer',
    color: color.format(-0.8),
  }),
  dropdown: {
    position: 'absolute',
    right: 0,
    left: 0,
  },
  error: css({
    width: '100%',
    marginTop: 6,
    textAlign: 'right',
  }),
  dropdownIcon: {
    position: 'absolute',
    right: 12,
  },
} as const;

export const ResultDropdown = memo(View);
